<template>
  <div id="app" class="container">
    <social-slide-element
      :layout="layout"
      :content="message"
    ></social-slide-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import max from "lodash/max";
import utils from "@/utils/utilities";

export default {
  name: "SocialSlide",
  mixins: [layoutMixin],
  components: {
    SocialSlideElement: () =>
      import("@/components/socialslide/SocialSlideElement"),
  },
  props: {
    feedContent: Object,
  },
  data() {
    return {
      mIndex: -1,
      fIndex: -1,
      timer: null,
    };
  },
  created() {
    //
  },
  mounted() {
    this.play();
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  computed: {
    message: function() {
      if (this.mIndex < 0 || this.fIndex < 0) return null;

      if (
        this.feedContent &&
        this.feedContent.messages &&
        this.feedContent.messages[this.feed.id]
      ) {
        return this.feedContent.messages[this.feed.id][this.mIndex];
      }
      return null;
    },
    feed: function() {
      if (this.fIndex < 0) return null;
      if (
        this.feedContent &&
        this.feedContent.contents &&
        this.feedContent.contents[this.fIndex]
      ) {
        return this.feedContent.contents[this.fIndex];
      }
      return null;
    },
  },
  watch: {
    message: function() {
      this.timer = setTimeout(() => {
        this.play();
      }, this.feedContent.pauseTime);
    },
  },
  methods: {
    play: function() {
      let bigFeed = max(Object.keys(this.feedContent.messages), function(o) {
        return this.feedContent.messages[o].length;
      });

      let maxMessages = this.feedContent.messages[bigFeed].length;
      let nFeed = this.feedContent.contents.length;

      var _fIndex = this.fIndex;
      var _mIndex = this.mIndex;

      var validMessage = false;

      do {
        _fIndex = (_fIndex + 1) % nFeed;

        if (_fIndex === 0) {
          _mIndex = (_mIndex + 1) % maxMessages;
        }

        validMessage =
          utils.isValid(this.feedContent.contents[_fIndex]) &&
          utils.isValid(
            this.feedContent.messages[this.feedContent.contents[_fIndex].id][
              _mIndex
            ]
          );
      } while (!validMessage);

      this.mIndex = _mIndex;
      this.fIndex = _fIndex;
    },
  },
};
</script>

<style></style>
